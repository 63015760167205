import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { StaticService } from './static.service';

@NgModule({
  imports: [
    TranslateModule.forChild(),
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
      maxOpened: 1
    })
  ],
  providers: [
    StaticService
  ]
})

export class CoreModule {}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CrudConfig } from '@ngx-ivengi/crud';
import { LxRequestFormModel } from './lx-request-form.model';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()

export class LxRequestFormService {

  constructor(
    private http: HttpClient,
    private config: CrudConfig,
    private translate: TranslateService,
  ) {}

  public submitRequestForm(formData: LxRequestFormModel): Observable<any> {
    return new Observable((observer) => {
      this.http.post(`${this.config.getApiUrl()}/form/request`, formData).subscribe((response) => {
        observer.next(response);
      }, (error) => {
        observer.error(error);
      });
    });
  }

  public getPageContent(): Observable<any> {
    let _lang = 'EN';
    if (this.translate.currentLang === 'en-US') {
      _lang = 'EN';
    } else {
      _lang = (this.translate.currentLang.indexOf('-') !== -1) ? this.translate.currentLang.substr(this.translate.currentLang.indexOf('-') + 1) : 'EN';
    }

    return new Observable((observer) => {
      this.http.get(`${environment.requestFormUrl}/mx${_lang}`).subscribe((response) => {
        observer.next(response);
      }, (error) => {
        observer.error(error);
      });
    });
  }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {CatalogListComponent} from './catalog-list/catalog-list.component';
import {CatalogSearchComponent} from './catalog-search/catalog-search.component';
import {CatalogComponent} from './catalog.component';
import {CatalogService} from './catalog.service';
import { CookieService } from 'ngx-cookie-service';
import {CatalogBasketComponent} from './catalog-basket/catalog-basket.component';
import {TypeaheadModule} from 'ngx-bootstrap';

@NgModule({
  declarations: [
    CatalogComponent,
    CatalogListComponent,
    CatalogSearchComponent,
    CatalogBasketComponent,
  ],
  exports: [
  ],
  imports: [
    SharedModule,
    TypeaheadModule.forRoot(),
  ],
  providers: [
    CatalogService,
    CookieService
  ]
})

export class CatalogModule {}

import { Component } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { AuthenticationService } from '@ngx-ivengi/authentication';

@Component({
  templateUrl: './authentication-restore-password-confirm.component.html'
})

export class AuthenticationRestorePasswordConfirmComponent {
  public password = '';
  public passwordMatch = '';

  constructor(
    private authService: AuthenticationService,
    private router: UIRouter
  ) {}

  public doPasswordConfirm(): void {
    this.authService.restorePassword(this.router.stateService.params.token || null, this.password).subscribe(
      () => {
        this.router.stateService.go('authentication.login', this.router.stateService.params, {reload: true});
      }
    );
  }
}

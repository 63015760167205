import {DownloadBase, DownloadType} from './download-base.model';

export class DownloadSingle extends DownloadBase {
  file: {
    fileName: string;
    _id: string;
  };
  type: DownloadType = DownloadType.SINGLE;
  imageSrc?: string;
  thumbnailAvailable: boolean;
  iconClass?: string;
  title: string;

  public setFile(file: any): DownloadSingle {
    this.file = file;
    return this;
  }

  fromResponse(response: any): void {
    super.fromResponse(response);

    this
      .setFile(response.files[0]);
  }
}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})

export class PaginationComponent implements OnChanges {
  @Output() private doChangePage = new EventEmitter<number>();
  @Input('currentPage') public currentPage: number;
  @Input('pagesVisible') private pagesVisible: number;
  @Input('itemsPerPage') private itemsPerPage: number;
  @Input('totalHits') private totalHits: number;

  public isLoaded = false;
  public pages = 0;
  public paginationView = [];

  constructor() {}

  public ngOnChanges(): void {
    this.isLoaded = !!(this.currentPage !== undefined && this.pagesVisible !== undefined && this.totalHits !== undefined);
    if (this.isLoaded) {
      this.initPagination();
    }
  }

  public changePage(method: string, page?: any): void {
    switch (method) {
      case 'next':
        if (this.currentPage !== this.pages) {
          this.doChangePage.emit(this.currentPage + 1);
        }
        break;

      case 'prev':
        if (this.currentPage > 1) {
          this.doChangePage.emit(this.currentPage - 1);
        }
        break;

      case 'goto':
        if (page) {
          this.doChangePage.emit(page);
        }
        break;
    }
  }

  private initPagination(): void {
    this.pages = Math.ceil(this.totalHits / this.itemsPerPage);
    this.paginationView = [];

    if (this.pages > this.pagesVisible) {
      let _numberOffset = Math.floor(this.pagesVisible / 2);
      let _leftOffset   = this.currentPage - _numberOffset;
      let _rightOffset  = this.currentPage + _numberOffset;
      let i;

      // LEFT BOUNDRY
      if (_leftOffset < 1) {
        i = 0;
        _rightOffset = this.pagesVisible;
      } else {
        i = _leftOffset - 1;
      }

      // RIGHT BOUNDRY
      if (_rightOffset > this.pages) {
        _rightOffset = this.pages;
        i = this.pages - this.pagesVisible;
      }

      for (i; i < _rightOffset ; i++) {
        this.paginationView.push(i + 1);
      }
    } else {
      this.paginationView = Array.from({length: this.pages}, (v,k) => k += 1);
    }
  }
}

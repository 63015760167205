import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import {DownloadListComponent} from './download-list/download-list.component';

export let CHILD_STATES: Ng2StateDeclaration[] = [
  {
    name: 'download',
    url: '/download',
    component: DownloadListComponent,
    data: {
      insecure: false
    }
  }
];

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: CHILD_STATES
    }),
    TranslateModule
  ],
  exports: [UIRouterModule]
})

export class DownloadRoutes {}

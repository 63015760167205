import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { ProfileRoutes } from '../profile/profile.routes';
import { AppRoutes } from '../../app.routes';
import {CatalogRoutes} from '../catalog/catalog.routes';
import {DownloadRoutes} from '../download/download.routes';
import { LxRequestFormRoutes } from '../lx-request-form/lx-request-form.routes';

@NgModule({
  imports: [
    AppRoutes,
    CatalogRoutes,
    DownloadRoutes,
    ProfileRoutes,
    LxRequestFormRoutes
  ],
  exports: [
    UIRouterModule
  ]
})

export class RoutingModule {}

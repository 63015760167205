import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AppConfig } from './components/config/app.config';
import { TranslateService } from '@ngx-translate/core';
import {whitelabel} from '../whitelabels/whitelabel';

@Injectable()
export class ApplicationRequestInterceptor implements HttpInterceptor {

  constructor(
    @Inject(AppConfig) private appConfig,
    private translate: TranslateService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(this.appConfig.apiUrl)) {
      req = req.clone({headers: new HttpHeaders()});
    } else {
      let headers = req.headers || new HttpHeaders();
      headers     = headers.set('Accept-Language', this.translate.currentLang);
      headers     = headers.set('X-Whitelabel', whitelabel.identifier);
      req         = req.clone({headers});
    }

    return next.handle(req);
  }
}

import { NgModule } from '@angular/core';
import { MatchValidator } from './match.validator';

@NgModule({
  declarations: [
    MatchValidator
  ],
  exports: [
    MatchValidator
  ]
})

export class ValidatorModule {}

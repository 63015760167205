import { Injectable } from '@angular/core';
import { User } from '../user/user.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CrudConfig } from '@ngx-ivengi/crud';

@Injectable()

export class ProfileService {

  constructor(
    private http: HttpClient,
    private config: CrudConfig
  ) {}

  public get(): Observable<User> {
    return new Observable((observer) => {
      this.http.get(`${this.config.getApiUrl()}/profile`).subscribe((response) => {
        const _user = new User;
        _user.fromResponse(response);
        observer.next(_user);
      }, (error) => {
        observer.next(error);
      });
    });
  }

  public postChangeRequest(changeRequestData): Observable<any> {
    return new Observable((observer) => {
      this.http.post(`${this.config.getApiUrl()}/profile/change-request`, changeRequestData).subscribe((response) => {
        observer.next(response);
      }, (error) => {
        observer.next(error);
      });
    });
  }
}

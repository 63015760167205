import { NgModule } from '@angular/core';
import { SlideshowComponent } from './slideshow.component';
import { SharedModule } from '../shared/shared.module';
import { SlideshowService } from './slideshow.service';

@NgModule({
  declarations: [
    SlideshowComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    SlideshowComponent
  ],
  providers: [
    SlideshowService
  ]
})

export class SlideshowModule {}

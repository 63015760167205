import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileComponent } from './profile.component';

export let CHILD_STATES: Ng2StateDeclaration[] = [
  {
    name: 'profile',
    url: '/profile',
    component: ProfileComponent,
    data: {
      insecure: false
    }
  }
];

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: CHILD_STATES
    }),
    TranslateModule
  ],
  exports: [UIRouterModule]
})

export class ProfileRoutes {}

import {DownloadBase, DownloadType} from './download-base.model';

export class DownloadMultiple extends DownloadBase {
  files: string[];
  type: DownloadType = DownloadType.MULTIPLE;
  imageSrc: string;
  title: string;

  public setFiles(files: string[]): DownloadMultiple {
    this.files = files;
    return this;
  }

  fromResponse(response: any): void {
    this
      .setFiles(response.files);
  }
}

import { Injectable } from '@angular/core';
import { CrudConfig, CrudService } from '@ngx-ivengi/crud';
import { User } from './user.model';
import { config, Observable, Observer } from 'rxjs/Rx';

@Injectable()

export class UserService extends CrudService<User> {
  public entityType = User;

  public registerAccount(userData: User): Observable<any> {
    return new Observable<any>((Observer) => {
      this.http.post(`${this.config.getApiUrl()}/account/register`, userData).subscribe((res) => {
        Observer.next(res);
      }, (err) => {
        Observer.error(err);
      });
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { CatalogService } from '../catalog.service';
import { CrudConfig } from '@ngx-ivengi/crud';
import { AuthenticationService } from '@ngx-ivengi/authentication';
import { DownloadService } from '../../download/download.service';
import { DownloadSingle } from '../../download/download-single.model';
import { TranslateService } from '@ngx-translate/core';
import { whitelabel } from '../../../../whitelabels/whitelabel';
import { Observable } from 'rxjs';

const mimeTypeToIcon = new Map();
mimeTypeToIcon
  .set('application/pdf', 'fa-file-pdf-o')
  .set('application/msword', 'fa-file-word-o')
  .set('application/vnd.ms-excel', 'fa-file-excel-o')
  .set('application/vnd.ms-powerpoint', 'fa-file-powerpoint-o')
  .set('application/x-rar-compressed', 'fa-file-archive-o')
  .set('application/x-7z-compressed', 'fa-file-archive-o')
  .set('application/zip', 'fa-file-archive-o')
  .set('image/gif', 'fa-file-image-o')
  .set('image/tiff', 'fa-file-image-o')
  .set('image/x-svg', 'fa-file-image-o')
  .set('image/x-3ds', 'fa-file-image-o')
  .set('application/eps', 'fa-file-image-o')
  .set('application/x-eps', 'fa-file-image-o')
  .set('image/eps', 'fa-file-image-o')
  .set('image/x-eps', 'fa-file-image-o')
  .set('application/acad', 'fa-file-image-o')
  .set('image/vnd.dwg', 'fa-file-image-o')
  .set('image/x-dwg', 'fa-file-image-o')
  .set('text/plain', 'fa-file-text-o')
  .set('text/xml', 'fa-file-excel-o')
  .set('text/csv', 'fa-file-excel-o');

const imgMimeTypes = new Map();
imgMimeTypes
  .set('image/png', '')
  .set('image/jpeg', '');

@Component({
  selector   : 'app-catalog-list',
  templateUrl: './catalog-list.component.html'
})

export class CatalogListComponent implements OnInit {
  public isConfigured = false;
  public groups = [];

  constructor(
    private config: CrudConfig,
    private authService: AuthenticationService,
    private catalogService: CatalogService,
    private downloadService: DownloadService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.catalogService.onBasketChange.subscribe(() => {
      const itemsInBasket = this.catalogService.getBasketItems();
      this.groups.forEach((group) => {
        group.items = group.items.map((item) => {
          item.inBasket = (itemsInBasket.indexOf(item.id) !== -1);
          return item;
        });
      });
    });
  }

  public setConfigured() {
    this.isConfigured = true;
  }

  public updateByResponse(response: any) {
    const itemsInBasket = this.catalogService.getBasketItems();

    // find the "file_type" filter and get the correct names!
    const filterFileType = response.filters.find((filter: any) => filter.id === 'FILE_TYPE');
    const idToName = (typeof(filterFileType) !== 'undefined') ? Object.assign({}, ...filterFileType.options.map(option => ({[option.value]: option.name}))) : {};

    const groups = [];

    response.items.forEach((item) => {
      item.inBasket = (itemsInBasket.indexOf(item.id) !== -1);
      item.imageSrc = `${this.config.getApiUrl()}/catalog/${item.id}/thumbnail?access_token=${this.authService.getAccessToken()}&whitelabel=${whitelabel.identifier}`;
      if (imgMimeTypes.has(item.fileType)) {
        item.thumbnailAvailable = true;
      } else {
        item.thumbnailAvailable = false;
        item.iconClass = (mimeTypeToIcon.has(item.fileType)) ? mimeTypeToIcon.get(item.fileType) : 'fa-file-o';
      }

      const filter = item.filters.find((_filter) => idToName[_filter._id] !== undefined);

      if (typeof(filter) !== 'undefined') {
        const group  = groups.find((_group) => _group.position === filter.position.toString());

        if (typeof(group) === 'undefined') {
          groups.push({
            name: new Observable((observer) => { observer.next(idToName[filter._id]) }),
            position: filter.position.toString(),
            items: [item],
          });
        } else {
          group.items.push(item);
        }
      } else {
        const group = groups.find((_group) => _group.position === '999');

        if (typeof(group) === 'undefined') {
          groups.push({
            name: this.translateService.get('catalog.generic_file_type'),
            position: '999',
            items: [item],
          });
        } else {
          group.items.push(item);
        }
      }
    });

    this.groups = groups.sort((n1: any, n2: any) => n1.position - n2.position);
  }

  public changeBasketStatus(element) {
    if (element.checked) {
      this.catalogService.addBasketItem(element.value);
    } else {
      this.catalogService.removeBasketItem(element.value);
    }
  }

  public downloadItem(itemId) {
    const downloadSingle = new DownloadSingle();
    downloadSingle.setFile(itemId);
    this.downloadService.add(downloadSingle).subscribe((response: any) => {
      const download = this.downloadService.entityFactory(response);
      if (download.status === 'AVAILABLE') {
        window.location.href = `${this.downloadService.getBaseUrl()}/${download.getId()}/download?whitelabel=${whitelabel.identifier}`;
      }
    });
  }
}

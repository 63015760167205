import {AuthenticationRestorePasswordRequestComponent} from './authentication-restore/authentication-restore-password-request.component';
import {AuthenticationRestorePasswordConfirmComponent} from './authentication-restore/authentication-restore-password-confirm.component';
import {AuthenticationLoginComponent} from './authentication-login/authentication-login.component';
import {Ng2StateDeclaration} from '@uirouter/angular';

export let CHILD_STATES: Ng2StateDeclaration[] = [
  {
    name: 'authentication.login',
    url: '/login',
    component: AuthenticationLoginComponent,
    data: {
      insecure: true
    }
  },
  {
    name: 'authentication.forgot-password',
    url: '/forgot-password',
    component: AuthenticationRestorePasswordRequestComponent,
    data: {
      insecure: true
    }
  },
  {
    name: 'authentication.restore-password',
    url: '/restore-password?token',
    component: AuthenticationRestorePasswordConfirmComponent,
    data: {
      insecure: true
    }
  }
];

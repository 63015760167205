import { Component, OnInit, ViewChild } from '@angular/core';
import { CatalogListComponent } from './catalog-list/catalog-list.component';
import { CatalogSearchComponent } from './catalog-search/catalog-search.component';
import { CatalogService } from './catalog.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html'
})

export class CatalogComponent implements OnInit {
  public totalHits = 0;
  public offset = 0;
  public itemsPerPage = 12;

  @ViewChild(CatalogSearchComponent)
  protected catalogSearchComponent: CatalogSearchComponent;

  @ViewChild(CatalogListComponent)
  protected catalogListComponent: CatalogListComponent;

  constructor(
    private catalogService: CatalogService
  ) {
    if (window.location.search.length > 1) {
      let httpParams = new HttpParams();
      const urlFilters = window.location.search.substring(window.location.search.indexOf('=') + 1).split('|');
      urlFilters.map((filter) => {
        const _mappedFilter = filter.split(':');
        httpParams = httpParams.set(_mappedFilter[0], _mappedFilter[1]);
      });

      this.catalogService.setHttpParams(httpParams);
    }
  }

  ngOnInit() {
    this.catalogSearchComponent.setConfigured();
    this.catalogListComponent.setConfigured();

    // execute initial filter call
    this.doFilter();
  }

  public doFilter(page?: number) {
    this.offset = 0;
    if (page && page > 0) {
      this.offset = page - 1;
    }

    let httpParams = this.catalogService.getHttpParams();

    httpParams = httpParams.set('offset', (this.offset * this.itemsPerPage).toString(10));
    httpParams = httpParams.set('limit', this.itemsPerPage.toString(10));

    this.catalogService.search(httpParams).subscribe((response) => {
      this.totalHits = response.pagination.total_hits;
      this.catalogSearchComponent.updateByResponse(response);
      this.catalogListComponent.updateByResponse(response);
    });
  }
}

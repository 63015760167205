import { Component, EventEmitter, Output } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import {AuthenticationService, CredentialsLoginRequest} from '@ngx-ivengi/authentication';

export interface LoginAttemptResponse {
  success: boolean;
}

@Component({
  templateUrl: './authentication-login.component.html'
})

export class AuthenticationLoginComponent {
  @Output() public onLoginAttempt: EventEmitter<LoginAttemptResponse> = new EventEmitter<LoginAttemptResponse>();

  public loginData = {
    user: '',
    pass: ''
  };

  constructor(
    private authService: AuthenticationService,
    private router: UIRouter
  ) {}

  public doLogin(): void {
    const _request: CredentialsLoginRequest = new CredentialsLoginRequest(this.loginData.user, this.loginData.pass);
    this.authService.login(_request).subscribe(() => {
      this.onLoginAttempt.emit({success: true});
      if (this.authService.getDeepLink()) {
        this.router.stateService.go(
          (<{state: string, params: {[key: string]: string} | null}>this.authService.getDeepLink()).state,
          (<{state: string, params: {[key: string]: string} | null}>this.authService.getDeepLink()).params
        );
      } else {
        this.router.stateService.go(this.authService.config.getRootState());
      }
    }, () => {
      this.onLoginAttempt.emit({success: false});
    });
  }
}

import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector : '[match]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: MatchValidator, multi: true}
  ]
})
export class MatchValidator {

  @Input() public match: any;

  public validator: Function;

  constructor() {
    this.validator = MatchValidator.validateMatch();
  }

  public static validateMatch() {
    return (control: FormControl, match) => {
      if (control.value !== match) {
        return {
          match: {
            valid: false
          }
        }
      }
      return null;
    }
  }

  public validate(control: FormControl) {
    return this.validator(control, this.match);
  }
}

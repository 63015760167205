import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import {CatalogComponent} from './catalog.component';
import { whitelabel } from '../../../whitelabels/whitelabel';

export let CHILD_STATES: Ng2StateDeclaration[] = [
  {
    name: 'catalog',
    url: (whitelabel.press) ? '/catalog?brand&filters' : '/catalog?filters',
    params: {
      filters: { type: 'string', raw: true }
    },
    component: CatalogComponent,
    data: {
      insecure: (whitelabel.press || whitelabel.professional) ? false : true
    }
  }
];

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: CHILD_STATES
    }),
    TranslateModule
  ],
  exports: [UIRouterModule]
})

export class CatalogRoutes {}

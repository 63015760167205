import { User } from '../user/user.model';

export class Address {
  public street: string = '';
  public houseNumber: string = '';
  public addition: string = '';
  public postalCode: string = '';
  public city: string = '';
  public country: string = '';

  public updateAddress(address): void {
    this.street = address.street || '';
    this.houseNumber = address.houseNumber || '';
    this.addition = address.addition || '';
    this.postalCode = address.postalCode || '';
    this.city = address.city || '';
    this.country = address.country || '';
  }
}

export class sampleSelectionOption {
  public selected: boolean = false;
  public name: string = '';
  public comment: string = '';

  constructor(name?) {
    this.name = (name) ? name : '';
  }
}

export class LxRequestFormModel {
  private sampleSelectionOptions: string[] = ['samples', 'furniture', 'samplesAndPricelist', 'other'];

  public salutation: string = '';
  public name: string = '';
  public companyName: string = '';
  public jobName: string = '';
  public address: Address = new Address;
  public useDeliveryAddress: boolean = false;
  public deliveryAddress?: Address = new Address;
  public sampleSelection: sampleSelectionOption[] = [];
  public comment: string  = '';

  public updateUser(user: User): void {
    this.salutation = user.salutation;
    this.name = (user.middleName) ? `${user.firstName} ${user.middleName} ${user.lastName}` : `${user.firstName} ${user.lastName}`;
    this.companyName = user.companyName || '';
    this.jobName = user.jobName || '';
    if (user.address) {
      this.address.updateAddress(user.address);
    }
  }

  constructor() {
    if (this.sampleSelectionOptions.length) {
      this.sampleSelectionOptions.forEach((sampleOption) => {
        this.sampleSelection.push(new sampleSelectionOption(sampleOption));
      });
    }
  }
}

export class homepageLinksModel {
  public link1: {
    active: boolean;
    url: string;
    title: string;
    image: {
      large: string;
      small: string;
    };
    link: string;
  };
  public link2: {
    active: boolean;
    url: string;
    title: string;
    image: {
      large: string;
      small: string;
    };
    link: string;
  };
  public link3: {
    active: boolean;
    url: string;
    title: string;
    image: {
      large: string;
      small: string;
    };
    link: string;
  };
  public link4: {
    active: boolean;
    url: string;
    title: string;
    image: {
      large: string;
      small: string;
    };
    link: string;
  };
  public link5: {
    active: boolean;
    url: string;
    title: string;
    image: {
      large: string;
      small: string;
    };
    link: string;
  };
  public link6: {
    active: boolean;
    url: string;
    title: string;
    image: {
      large: string;
      small: string;
    };
    link: string;
  };
}

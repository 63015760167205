import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
// import { HomeComponent } from './components/home/home.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { UserCreateComponent } from './components/user/user-create.component';
import { CollectionComponent } from './components/collection/collection.component';

export let CHILD_STATES: Ng2StateDeclaration[] = [
  // {
  //   name: 'home',
  //   url: '/',
  //   component: HomeComponent,
  //   data: {
  //     insecure: true
  //   }
  // },
  {
    name: 'authentication',
    url: '/authentication',
    component: AuthenticationComponent,
    data: {
      insecure: true,
    }
  },
  {
    name: 'create-account',
    url: '/create-account',
    component: UserCreateComponent,
    data: {
      insecure: true
    }
  },
  {
    name: 'collection',
    url: '/collection',
    component: CollectionComponent,
    data: {
      insecure: true
    }
  }
];

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: CHILD_STATES
    }),
    TranslateModule
  ],
  exports: [UIRouterModule]
})

export class AppRoutes {}

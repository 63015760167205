import { Component, OnInit } from '@angular/core';
import { CatalogService } from '../catalog.service';
import { DownloadService } from '../../download/download.service';
import { CrudConfig } from '@ngx-ivengi/crud';
import { DownloadMultiple } from '../../download/download-multiple.model';
import { HttpParams } from '@angular/common/http';
import { AuthenticationService } from '@ngx-ivengi/authentication';
import { whitelabel } from '../../../../whitelabels/whitelabel';

const mimeTypeToIcon = new Map();
mimeTypeToIcon
  .set('application/pdf', 'fa-file-pdf-o')
  .set('application/msword', 'fa-file-word-o')
  .set('application/vnd.ms-excel', 'fa-file-excel-o')
  .set('application/vnd.ms-powerpoint', 'fa-file-powerpoint-o')
  .set('application/x-rar-compressed', 'fa-file-archive-o')
  .set('application/x-7z-compressed', 'fa-file-archive-o')
  .set('application/zip', 'fa-file-archive-o')
  .set('image/gif', 'fa-file-image-o')
  .set('image/tiff', 'fa-file-image-o')
  .set('image/x-svg', 'fa-file-image-o')
  .set('image/x-3ds', 'fa-file-image-o')
  .set('application/eps', 'fa-file-image-o')
  .set('application/x-eps', 'fa-file-image-o')
  .set('image/eps', 'fa-file-image-o')
  .set('image/x-eps', 'fa-file-image-o')
  .set('application/acad', 'fa-file-image-o')
  .set('image/vnd.dwg', 'fa-file-image-o')
  .set('image/x-dwg', 'fa-file-image-o')
  .set('text/plain', 'fa-file-text-o')
  .set('text/xml', 'fa-file-excel-o')
  .set('text/csv', 'fa-file-excel-o');

const imgMimeTypes = new Map();
imgMimeTypes
  .set('image/png', '')
  .set('image/jpeg', '');

interface IBasketItem {
  id: string;
  title: string;
  imageSrc: string;
  fileType: string;
  thumbnailAvailable: string;
  iconClass?: string;
}

@Component({
  selector   : 'app-catalog-basket',
  templateUrl: './catalog-basket.component.html'
})

export class CatalogBasketComponent implements OnInit {
  public items: IBasketItem[] = [];

  constructor(
    private config: CrudConfig,
    private catalogService: CatalogService,
    private downloadService: DownloadService,
    private authService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.catalogService.onBasketChange.subscribe(() => {
      this.updateBasket();
    });

    this.updateBasket();
  }

  private updateBasket(): void {
    const itemIds = this.catalogService.getBasketItems();

    if (itemIds.length === 0) {
      this.items = [];
    } else {
      let httpParams = new HttpParams();
      httpParams = httpParams.set('limit', itemIds.length.toString());
      httpParams = httpParams.set('id', itemIds.toString());

      this.catalogService.search(httpParams).subscribe((response) => {
        this.items = response.items.map(item => {
          if (imgMimeTypes.has(item.fileType)) {
            item.thumbnailAvailable = true;
            item.imageSrc = `${this.config.getApiUrl()}/catalog/${item.id}/thumbnail?access_token=${this.authService.getAccessToken()}&whitelabel=${whitelabel.identifier}`;
          } else {
            item.thumbnailAvailable = false;
            item.iconClass = (mimeTypeToIcon.has(item.fileType)) ? mimeTypeToIcon.get(item.fileType) : 'fa-file-o';
          }
          return item;
        }).sort((a, b) => {
          return itemIds.indexOf(a.id) - itemIds.indexOf(b.id);
        });
      });
    }
  }

  public onDelete(itemId): void {
    this.catalogService.removeBasketItem(itemId);
  }

  public onDownload(): void {
    const downloadCollection = new DownloadMultiple();
    downloadCollection.setFiles(this.items.map(item => item.id));
    this.downloadService.add(downloadCollection).subscribe((response: any) => {
      const download = this.downloadService.entityFactory(response);
      if (download.status === 'AVAILABLE') {
        window.location.href = `${this.downloadService.getBaseUrl()}/${download.getId()}/download?whitelabel=${whitelabel.identifier}`;
      }
    });
  }
}

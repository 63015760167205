import { AfterViewChecked, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { StaticService } from '../core/static.service';
import { SlideshowService } from './slideshow.service';

export class SlideshowModel {
  slides: [
    { small: string; large: string; }
  ];
}

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html'
})

export class SlideshowComponent implements OnInit, AfterViewChecked, OnDestroy {
  public slideshow: SlideshowModel = new SlideshowModel();
  private bCanInitScripting = false;

  constructor(
    private slideshowService: SlideshowService,
    private staticService: StaticService,
    @Inject('Window') private window: any
  ) {}

  public ngOnInit(): void {
    this.getSlideShow();
  }

  private getSlideShow(): void {
    this.staticService.getSlideshow().subscribe((res) => {
      if (res.slides && res.slides.length > 0) {
        this.slideshow.slides = res.slides;
        this.bCanInitScripting = true;
        this.slideshowService.slideshowVisible.emit(true);
      }
    }, (err) => {
      console.error('Get slideshow error', err);
    });
  }

  public ngOnDestroy(): void {
    this.slideshowService.slideshowVisible.emit(false);
  }

  public ngAfterViewChecked(): void {
    if (this.bCanInitScripting && this.slideshow.slides && this.slideshow.slides.length > 0) {
      this.window.slider.init();
      this.bCanInitScripting = false;
    }
  }
}

import { NgModule } from '@angular/core';
import { CollectionComponent } from './collection.component';
import { SharedModule } from '../shared/shared.module';
// import { SlideshowModule } from '../slideshow/slideshow.module';

@NgModule({
  declarations: [
    CollectionComponent
  ],
  imports: [
    SharedModule,
    // SlideshowModule
  ]
})

export class CollectionModule {}

import {CrudModel} from '@ngx-ivengi/crud';

export enum DownloadType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE'
}

export abstract class DownloadBase extends CrudModel {
  createdAt: string;
  type: DownloadType;
  mimeType: string;
  fileName: string;
  status: string;
  fileSize: string;

  public setType(type: DownloadType): DownloadBase {
    this.type = type;
    return this;
  }

  public setCreatedAt(createdAt: string): DownloadBase {
    this.createdAt = createdAt;
    return this;
  }

  public setFileSize(fileSize: number): DownloadBase {
    this.fileSize = `${Math.floor(fileSize / 1000)} kb`;
    return this;
  }

  public setMimeType(mimeType: string): DownloadBase {
    this.mimeType = mimeType;
    return this;
  }

  public setFileName(fileName: string): DownloadBase {
    this.fileName = fileName;
    return this;
  }

  public setStatus(status: string): DownloadBase {
    this.status = status;
    return this;
  }

  public setFilesize(filesize: string): DownloadBase {
    this.fileSize = filesize;
    return this;
  }

  fromResponse(response: any): void {
    this
      .setId(response._id)
      .setType(response.type)
      .setCreatedAt(response.createdAt)
      .setFileSize(response.fileSize)
      .setMimeType(response.mimeType)
      .setFileName(response.fileName)
      .setStatus(response.status)
      .setFilesize(response.fileSize);
  }
}

import { Component, OnInit } from '@angular/core';
import { ProfileService } from './profile.service';
import { User } from '../user/user.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { whitelabel } from '../../../whitelabels/whitelabel';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})

export class ProfileComponent implements OnInit {
  public whitelabel = whitelabel;
  public profile: User;
  public finishedLoadingProfile = false;
  public changeRequest = '';
  public changeRequestSuccess = false;

  constructor(
    private profileService: ProfileService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.doGetProfile();
  }

  private doGetProfile(): void {
    this.profileService.get().subscribe((user) => {
      this.profile = user;
      this.finishedLoadingProfile = true;
    }, () => {
      this.toastr.error(this.translate.instant('generic.errors.generic-error'));
    })
  }

  public submitChangeRequest(form): void {
    if (form && form.form.valid && this.changeRequest.length) {
      this.profileService.postChangeRequest({message: this.changeRequest}).subscribe(() => {
        this.changeRequestSuccess = true;
        this.toastr.success(this.translate.instant('user.profile.requestChange.success'));
      }, () => {
        this.toastr.error(this.translate.instant('generic.errors.generic-error'));
      });
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CoreModule } from './components/core/core.module';
import { RoutingModule } from './components/routing/routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomeModule } from './components/home/home.module';
import { ProfileModule } from './components/profile/profile.module';
import { ConfigModule } from './components/config/config.module';
import { ApplicationRequestInterceptor } from './app.request.interceptor';
import { CatalogModule } from './components/catalog/catalog.module';
import { DownloadModule } from './components/download/download.module';
import { UserModule } from './components/user/user.module';
import { AuthUIRouter } from '@ngx-ivengi/auth-ui-router';
import { AuthenticationModule } from './components/authentication/authentication.module';
import { CollectionModule } from './components/collection/collection.module';
import { LocaleModule, LocaleService } from '@ngx-ivengi/locale';
import { AppLanguageComponent } from './app-language.component';
import { environment } from '../environments/environment';
import { LxRequestFormModule } from './components/lx-request-form/lx-request-form.module';
import { Angulartics2UirouterModule } from 'angulartics2/uiroutermodule';

const domainGroup = environment.domainGroups.find(_domainGroup => {
  return (typeof(_domainGroup.find(_domainSettings => (_domainSettings.domain === window.location.host))) !== 'undefined');
});

const domainSettings = domainGroup.find(_domainSettings => (_domainSettings.domain === window.location.host));

const languages = ['en-US', 'nl-NL', 'de-DE', 'fr-FR'].filter((navItem) => (domainSettings.language.visibleLanguages.indexOf(navItem) !== -1));
const browserCultureLang = LocaleService.getBrowserCultureLang();

LocaleService.setAvailableLanguageISO(languages);
LocaleService.setDefaultLanguageISO((languages.indexOf(browserCultureLang) !== -1) ? browserCultureLang : languages[0]);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

const _currentUrl = window.location.href;
let _gtmTag = 'GTM-N9VVDTT';

if (_currentUrl.indexOf('professional.leolux-lx.com') !== -1) {
  _gtmTag = 'GTM-N9VVDTT';
} else if (_currentUrl.indexOf('press.leolux.com') !== -1) {
  _gtmTag = 'GTM-P2KS5RX';
} else if (_currentUrl.indexOf('images.pode.eu') !== -1) {
  _gtmTag = 'GTM-K7DGKMH';
} else if (_currentUrl.indexOf('images.leolux.com') !== -1) {
  _gtmTag = 'GTM-WX66TVQ';
}

@NgModule({
  declarations: [
    AppComponent,
    AppLanguageComponent,
  ],
  imports     : [
    BrowserModule,
    BrowserAnimationsModule,
    UIRouterModule.forRoot({
      otherwise: '/catalog' // Formerly /home
    }),
    TranslateModule.forRoot({
      loader: {
        provide   : TranslateLoader,
        useFactory: (createTranslateLoader),
        deps      : [HttpClient]
      }
    }),
    Angulartics2UirouterModule.forRoot({
      developerMode: (environment.production) ? false : true,
      pageTracking: {
        clearQueryParams: true
      },
      gtm: {
        userId: _gtmTag
      }
    }),
    LocaleModule,
    AuthenticationModule,
    AuthUIRouter,
    ConfigModule,
    CoreModule,
    HomeModule,
    CatalogModule,
    RoutingModule,
    ProfileModule,
    UserModule,
    DownloadModule,
    CollectionModule,
    LxRequestFormModule
  ],
  providers   : [
    { provide: 'Window',  useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: ApplicationRequestInterceptor, multi: true },
  ],
  bootstrap   : [AppComponent]
})
export class AppModule {}

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {AuthenticationComponent} from './authentication.component';
import {AuthenticationLoginComponent} from './authentication-login/authentication-login.component';
import {AuthenticationRestorePasswordRequestComponent} from './authentication-restore/authentication-restore-password-request.component';
import {AuthenticationRestorePasswordConfirmComponent} from './authentication-restore/authentication-restore-password-confirm.component';
import {CHILD_STATES} from './authentication.routes';
import {UIRouterModule} from '@uirouter/angular';
import { ValidatorModule } from '../validator/validator.module';


@NgModule({
  declarations: [
    AuthenticationComponent,
    AuthenticationLoginComponent,
    AuthenticationRestorePasswordRequestComponent,
    AuthenticationRestorePasswordConfirmComponent,
  ],
  exports: [
  ],
  imports: [
    SharedModule,
    UIRouterModule.forChild({
      states: CHILD_STATES
    }),
    ValidatorModule
  ],
  providers: []
})

export class AuthenticationModule {}

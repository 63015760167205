import {CrudModel} from '@ngx-ivengi/crud';

export class User extends CrudModel {
  public password: string;
  public passwordConfirmation: string;

  public email: string;
  public salutation: string = '';
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public companyName: string;
  public jobName: string;
  public phoneNumber: string;
  public address: Address = new Address();
  public active: boolean = true;
  public newsletterConfirmation: boolean = false;

  public setSalutation(salutation: string): User {
    if (typeof salutation === 'string') {
      this.salutation = salutation;
    }
    return this;
  }

  public getSalutation(): string {
    return this.salutation;
  }

  public setFirstName(firstName: string): User {
    if (typeof firstName === 'string') {
      this.firstName = firstName;
    }
    return this;
  }

  public getFirstName(): string {
    return this.firstName;
  }

  public setMiddleName(middleName: string): User {
    if (typeof middleName === 'string') {
      this.middleName = middleName;
    }
    return this;
  }

  public getMiddleName(): string {
    return this.middleName;
  }

  public setLastName(lastName: string): User {
    if (typeof lastName === 'string') {
      this.lastName = lastName;
    }
    return this;
  }

  public getLastName(): string {
    return this.lastName;
  }

  public getFullName(): string {
    return this.firstName + ((this.middleName.length > 0) ? ' ' + this.middleName : '') + ' ' + this.lastName;
  }

  public setCompanyName(value: string): User {
    if (typeof value === 'string') {
      this.companyName = value;
    }
    return this;
  }

  public getCompanyName(): string {
    return this.companyName;
  }

  public setJobName(value: string): User {
    if (typeof value === 'string') {
      this.jobName = value;
    }
    return this;
  }

  public getJobName(): string {
    return this.jobName;
  }

  public setPhoneNr(phoneNr: string): User {
    if (typeof phoneNr === 'string') {
      this.phoneNumber = phoneNr;
    }
    return this;
  }

  public getPhoneNr(): string {
    return this.phoneNumber;
  }

  public setEmail(email: string): User {
    if (typeof email === 'string') {
      this.email = email;
    }
    return this;
  }

  public getEmail(): string {
    return this.email;
  }

  public setAddress(data: any): User {
    this.address = new Address(data);
    return this;
  }

  public getAddress(): Address {
    return this.address;
  }

  public setPassword(password: string): User {
    if (typeof password === 'string') {
      this.password = password;
    }
    return this;
  }

  public getPassword(): string {
    return this.password;
  }

  public setActive(active: boolean): User {
    if (typeof active === 'boolean') {
      this.active = active;
    }
    return this;
  }

  public setNewsletterConfirmation(newsletter: boolean): User {
    if (typeof newsletter === 'boolean') {
      this.newsletterConfirmation = newsletter;
    }
    return this;
  }

  public fromResponse(response: any): void {
    if (response) {
      this
        .setId(response._id)
        .setSalutation(response.salutation || '')
        .setFirstName(response.firstName)
        .setMiddleName(response.middleName || '')
        .setLastName(response.lastName)
        .setEmail(response.email)
        .setCompanyName(response.companyName)
        .setJobName(response.jobName || '')
        .setPhoneNr(response.phoneNumber || '')
        .setAddress(response.address)
        .setActive(response.active)
        .setNewsletterConfirmation(response.newsletterConfirmation);
    }
  }
}


export class Address {
  public street: string = '';
  public houseNumber: string = '';
  public addition?: string = '';
  public postalCode: string = '';
  public city: string = '';
  public country: string = '';

  public constructor(data?: any) {
    if (!data) {
      return this;
    }

    return Object.assign(this, data);
  }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HttpClient } from '@angular/common/http';
import { HomeComponent } from './home.component';
// import { SlideshowModule } from '../slideshow/slideshow.module';

@NgModule({
  declarations: [
    HomeComponent
  ],
  exports: [
    HomeComponent
  ],
  imports: [
    SharedModule,
    // SlideshowModule
  ],
  providers: [
    HttpClient
  ]
})

export class HomeModule {}

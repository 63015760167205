import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { LxRequestFormModel } from './lx-request-form.model';
import { ProfileService } from '../profile/profile.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LxRequestFormService } from './lx-request-form.service';

interface Country {
  code: string;
  name: string;
}

interface Salutation {
  code: string;
  name: string;
}

interface PageContent {
  content: {
    title: string;
    block_1: string;
    block_2: string;
  }
}

@Component({
  selector: 'app-lx-request-form',
  templateUrl: './lx-request-form.component.html'
})

export class LxRequestFormComponent implements OnInit {
  public form: LxRequestFormModel = new LxRequestFormModel();
  public countryList: Country[] = [];
  public salutations: Salutation[] = [];
  public finishedLoadingProfile = false;
  public sampleSelected = false;
  public submitSuccess = false;
  public pageContent: PageContent;
  public contentLoaded = false;

  constructor(
    @Inject('Window') private window,
    private requestFormService: LxRequestFormService,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private elRef: ElementRef,
  ) {
    this.translate.get('country').subscribe((countries) => {
      Object.entries(countries).map((country) => {
        this.countryList.push({code: country[0].toString(), name: country[1].toString()});
      });
    });

    this.translate.get('salutations').subscribe((salutations) => {
      Object.entries(salutations).map((salutation) => {
        this.salutations.push({code: salutation[0].toString(), name: salutation[1].toString()});
      });
    });
  }

  public ngOnInit(): void {
    this.getProfileData();
    this.getPageContent();
  }

  public onSubmit(form): void {
    if (form && form.form.valid && this.sampleSelected) {
      this.requestFormService.submitRequestForm(this.form).subscribe(() => {
        this.submitSuccess = true;
        this.toastr.success(this.translate.instant('lx-request-form.successfull-submit'));
      }, () => {
        this.toastr.error(this.translate.instant('generic.errors.generic-error'));
      });
    } else {
      this.toastr.error(this.translate.instant('lx-request-form.invalid-submit'));
      const _firstInvalidInput = this.elRef.nativeElement.querySelector('.form-control.ng-invalid');

      // +115px to compensate for the sticky header
      if (_firstInvalidInput) {
        this.window.scrollTo(0, _firstInvalidInput.scrollTop + 115);
        _firstInvalidInput.focus();
      }
    }
  }

  private getProfileData(): void {
    this.profileService.get().subscribe((user) => {
      this.form.updateUser(user);
      this.finishedLoadingProfile = true;
    }, () => {
      this.finishedLoadingProfile = true;
    });
  }

  private getPageContent(): void {
    this.requestFormService.getPageContent().subscribe((response) => {
      this.pageContent = response;
      this.contentLoaded = true;
    });
  }

  public checkFormValidity(): void {
    if (this.form.sampleSelection.filter(sampleOption => sampleOption.selected === true).length) {
      this.sampleSelected = true;
    } else {
      this.sampleSelected = false;
    }
  }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { UserCreateComponent } from './user-create.component';
import { UserService } from './user.service';
import { ValidatorModule } from '../validator/validator.module';

@NgModule({
  declarations: [
    UserCreateComponent
  ],
  imports: [
    SharedModule,
    ValidatorModule
  ],
  providers: [
    UserService
  ]
})

export class UserModule {}

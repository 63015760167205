import {InjectionToken} from '@angular/core';
import {environment} from '../../../environments/environment';

interface IAppConfig {
    name: string;
    version: string;
    apiUrl: string;
}

export const AppDiConfig: IAppConfig = {
    name: 'Leolux beeldenbank',
    version: '0.0.1',
    apiUrl: environment.apiUrl
};

export let AppConfig = new InjectionToken <IAppConfig>('AppConfig');

import { UIRouter } from '@uirouter/angular';
import { Component } from '@angular/core';
import {AuthenticationService, ForgotPasswordRequest} from '@ngx-ivengi/authentication';

@Component({
  templateUrl: './authentication-restore-password-request.component.html'
})

export class AuthenticationRestorePasswordRequestComponent {
  public email: string;

  constructor(
    private authService: AuthenticationService,
    private router: UIRouter
  ) {}

  public doPasswordRequest(): void {
    this.authService.forgotPassword(new ForgotPasswordRequest(this.email)).subscribe(
      () => {
        this.router.stateService.go('login', this.router.stateService.params, {reload: true});
      }
    );
  }
}

import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { CatalogService } from '../catalog.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { UIRouter } from '@uirouter/angular';
import { Observable } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { mergeMap } from 'rxjs/internal/operators';
import { whitelabel } from '../../../../whitelabels/whitelabel';

const filtersToRemove = (whitelabel.press) ? [] : ['BRAND'];

@Component({
  selector   : 'app-catalog-search',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  templateUrl: './catalog-search.component.html'
})

export class CatalogSearchComponent {
  @Output()
  private doFilter = new EventEmitter<void>();
  private mobileBreakpoint = 960; // xlarge
  public isConfigured = false;
  public filters = [];
  public filterViewStatus = {};
  public typeaheadDataSource: Observable<any>;
  public typeaheadSelected = '';
  public typeaheadNothingFound = false;

  constructor(
    private catalogService: CatalogService,
    private router: UIRouter,
    private location: Location,
    @Inject('Window') private window: any,
  ) {
    this.typeaheadDataSource = Observable
      .create((observer: any) => {
        observer.next(this.typeaheadSelected);
      })
      .pipe(
        mergeMap((token: string) => this.catalogService.autocomplete(token))
      );
  }

  public typeaheadOnSelect(e: TypeaheadMatch): void {
    this.onFilterChange('autocomplete', e.item);
  }

  public typeaheaNoResults(nothingFound: boolean): void {
    this.typeaheadNothingFound = nothingFound;
  }

  public doClearTypeAhead(): void {
    this.typeaheadSelected = '';
    this.onFilterChange('autocomplete', {id: ''});
  }

  public onKey(e: any): void {
    if (e.key && e.key === 'Enter' && this.typeaheadSelected === '') {
      this.onFilterChange('autocomplete', {id: ''});
    }
  }

  public setConfigured(): void {
    this.isConfigured = true;
  }

  public updateByResponse(response: any): void {
    this.filters = response.filters
      .filter(filter => filtersToRemove.indexOf(filter.id) === -1)
      .map((filter) => {
        if (!this.filterViewStatus[filter.name]) {
          if (filter.selected.length > 0) {
            this.filterViewStatus[filter.name] = {
              'showAll': true,
              'opened': true
            };
          } else if (this.window.innerWidth && this.window.innerWidth < this.mobileBreakpoint) {
            this.filterViewStatus[filter.name] = {
              'showAll': false,
              'opened': false
            };
          } else {
            this.filterViewStatus[filter.name] = {
              'showAll': false,
              'opened': true
            };
          }
        }

        filter.options = filter.options
          .map((option: any) => {
            option.value = option.id || option.value;
            return option;
          });

        return filter;
      })
      .filter(filter => filter.options.length > 0);
  }

  public onFilterChange(filterName, element): void {
    let httpParams = new HttpParams();
    let _urlParams = new HttpParams();

    if (filterName === 'autocomplete') {
      // unselect ALL filters
      this.filters.forEach((_filter) => {
        _filter.selected = [];
      });

      // selected a filter in the autocomplete
      if (element.filter && element.filter !== '') {
        this.filters.forEach((_filter) => {
          const matchingOption = _filter.options.find((option) => option.value === element.filter);
          if (typeof(matchingOption) !== 'undefined') {
            _filter.selected.push(element.filter);
          }
        });
      }

      if (element.id && element.id !== '') {
        httpParams = httpParams.set('id', element.id);
      } else if (httpParams.has('id')) {
        httpParams = httpParams.delete('id');
      }
    } else {
      const filter = this.filters.find(_filter => _filter.name === filterName);

      if (element.checked) {
        if (filter.selected.indexOf(element.value) === -1) {
          filter.selected.push(element.value);
        }
      } else {
        filter.selected = filter.selected.filter((filterValue) => {
          return filterValue !== element.value;
        });
      }
    }

    this.filters.forEach(_filter => {
      if (_filter.selected.length > 0) {
        httpParams = httpParams.set(_filter.id, _filter.selected);

        if (_filter.name !== 'brand') {
          _urlParams = _urlParams.set(_filter.id, _filter.selected);
        }
      }
    });

    // Parse and update the URL
    const _urlParamsLength = _urlParams.keys().length;
    let searchQuery = '';
    if (_urlParamsLength) {
      searchQuery = '?filters=';

      _urlParams.keys().forEach((key, index) => {
        searchQuery = `${searchQuery}${key}:${_urlParams.getAll(key).join(',')}${(index + 1 === _urlParamsLength) ? '' : '|'}`;
      });
    }
    this.location.go(`${this.router.stateService.href('catalog', {filters: null})}${searchQuery}`);

    // Update content
    this.catalogService.setHttpParams(httpParams);
    this.doFilter.emit();
  }

  public resetFilters(): void {
    let httpParams = new HttpParams();
    let searchQuery = '';

    // reset search query
    this.typeaheadSelected = '';
    this.location.go(`${this.router.stateService.href('catalog', {filters: null})}${searchQuery}`);
    this.catalogService.setHttpParams(httpParams);
    this.doFilter.emit();
  }

  public watchWindowSize(event): void {
    let _windowWatcher = null;
    if (_windowWatcher) {
      clearTimeout(_windowWatcher);
      _windowWatcher = null;
    }

    _windowWatcher = setTimeout(() => {
      if (event !== undefined && event.currentTarget !== undefined) {
        this.toggleAllFilters((event.currentTarget.innerWidth && event.currentTarget.innerWidth < this.mobileBreakpoint));
      }
      _windowWatcher = null;
    }, 500);
  }

  private toggleAllFilters(closedState: boolean): void {
    Object.keys(this.filterViewStatus).map((filterKey) => {
      if (this.filterViewStatus[filterKey].opened !== undefined) {
        this.filterViewStatus[filterKey].opened = !closedState;
      }

      if (this.filterViewStatus[filterKey].showall !== undefined && closedState) {
        this.filterViewStatus[filterKey].showall = false;
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { StateService } from '@uirouter/angular';
import { whitelabel } from '../whitelabels/whitelabel';

@Component({
  selector: 'app-language',
  template: `
      <ul class="menu horizontal align-right" *ngIf="navItems.length > 1">
        <li class="menu-item divider" *ngIf="!whitelabel.leolux && !whitelabel.pode">|</li>
        <li class="menu-item lang-menu-item" *ngFor="let navItem of navItems">
            <a (click)="switch(navItem)" class="lang-item lang-item-{{ navItem.name | lowercase }}"
               [ngClass]="(currentLang === navItem.languageISO) ? 'active' : ''" data-close="mobile-menu">{{ navItem.name }}</a>
        </li>
      </ul>
  `
})
export class AppLanguageComponent implements OnInit {
  public navItems: any = null;
  public currentLang: string;
  public whitelabel = whitelabel;

  private domainGroup;
  private domainSettings;

  public constructor(
    private translate: TranslateService,
    private stateService: StateService,
  ) {
    this.currentLang = this.translate.currentLang;
  }

  ngOnInit(): void {
    this.domainGroup = environment.domainGroups.find(_domainGroup => {
      return (typeof(_domainGroup.find(_domainSettings => (_domainSettings.domain === window.location.host))) !== 'undefined');
    });

    this.domainSettings = this.domainGroup.find(_domainSettings => (_domainSettings.domain === window.location.host));

    this.navItems = [
      { name: 'NL', languageISO: 'nl-NL' },
      { name: 'EN', languageISO: 'en-US' },
      { name: 'DE', languageISO: 'de-DE' },
      { name: 'FR', languageISO: 'fr-FR' }
    ].filter((navItem) => {
      return (this.domainSettings.language.visibleLanguages.indexOf(navItem.languageISO) !== -1);
    });
  }

  public switch(navItem: any): void {
    this.translate.use(navItem.languageISO).subscribe(() => {
      this.currentLang = navItem.languageISO;
      this.stateService.reload();
    });
  }
}

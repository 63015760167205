import { Component, OnInit } from '@angular/core';
import { CrudConfig, RequestParams } from '@ngx-ivengi/crud';
import { DownloadService } from '../download.service';
import { DownloadSingle } from '../download-single.model';
import { DownloadMultiple } from '../download-multiple.model';
import { DownloadBase } from '../download-base.model';
import { AuthenticationService } from '@ngx-ivengi/authentication';
import { whitelabel } from '../../../../whitelabels/whitelabel';

const mimeTypeToIcon = new Map();
mimeTypeToIcon
  .set('application/pdf', 'fa-file-pdf-o')
  .set('application/msword', 'fa-file-word-o')
  .set('application/vnd.ms-excel', 'fa-file-excel-o')
  .set('application/vnd.ms-powerpoint', 'fa-file-powerpoint-o')
  .set('application/x-rar-compressed', 'fa-file-archive-o')
  .set('application/x-7z-compressed', 'fa-file-archive-o')
  .set('application/zip', 'fa-file-archive-o')
  .set('image/gif', 'fa-file-image-o')
  .set('image/tiff', 'fa-file-image-o')
  .set('image/x-svg', 'fa-file-image-o')
  .set('image/x-3ds', 'fa-file-image-o')
  .set('application/eps', 'fa-file-image-o')
  .set('application/x-eps', 'fa-file-image-o')
  .set('image/eps', 'fa-file-image-o')
  .set('image/x-eps', 'fa-file-image-o')
  .set('application/acad', 'fa-file-image-o')
  .set('image/vnd.dwg', 'fa-file-image-o')
  .set('image/x-dwg', 'fa-file-image-o')
  .set('text/plain', 'fa-file-text-o')
  .set('text/xml', 'fa-file-excel-o')
  .set('text/csv', 'fa-file-excel-o');

const imgMimeTypes = new Map();
imgMimeTypes
  .set('image/png', '')
  .set('image/jpeg', '');


@Component({
  selector: 'app-download-list',
  templateUrl: './download-list.component.html'
})

export class DownloadListComponent implements OnInit {
  public items: (DownloadSingle|DownloadMultiple)[] = [];

  constructor(
    private config: CrudConfig,
    private downloadService: DownloadService,
    private authService: AuthenticationService
  ) {}

  public ngOnInit(): void {
    const requestParams = new RequestParams();
    requestParams.pagination.limit = 999;

    this.downloadService.getAll(requestParams).subscribe((response) => {
      this.items = response;
      this.items.forEach((item) => {
        if (item.type === 'SINGLE') {
          if (imgMimeTypes.has(item.mimeType)) {
            (<DownloadSingle>item).thumbnailAvailable = true;
            item.imageSrc = `${this.config.getApiUrl()}/catalog/${(<DownloadSingle>item).file._id}/thumbnail?access_token=${this.authService.getAccessToken()}&whitelabel=${whitelabel.identifier}`;
          } else {
            (<DownloadSingle>item).thumbnailAvailable = false;
            (<DownloadSingle>item).iconClass = (mimeTypeToIcon.has(item.mimeType)) ? mimeTypeToIcon.get(item.mimeType) : 'fa-file-o';
          }
          item.title = (<DownloadSingle>item).file.fileName;
        } else {
          item.title = item.fileName;
        }
      });
    });
  }

  public downloadItem(download: DownloadBase) {
    if (download.status === 'AVAILABLE') {
      window.location.href = `${this.downloadService.getBaseUrl()}/${download.getId()}/download?whitelabel=${whitelabel.identifier}`;
    }
  }
}

import { Inject, NgModule } from '@angular/core';
import { AppConfig, AppDiConfig } from './app.config';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CrudConfig, CrudModule } from '@ngx-ivengi/crud';
import {AuthenticationModule} from '../authentication/authentication.module';
import {AuthenticationService} from '@ngx-ivengi/authentication';
import { whitelabel } from '../../../whitelabels/whitelabel';
import {LocaleService} from '@ngx-ivengi/locale';

@NgModule({
  imports  : [AuthenticationModule, TranslateModule.forChild(), CrudModule],
  providers: [
    {
      provide : AppConfig,
      useValue: AppDiConfig
    }
  ]
})
export class ConfigModule {
  constructor(
    @Inject(AppConfig) private appConfig,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private crudConfig: CrudConfig,
  ) {
    this.crudConfig.setApiUrl(this.appConfig.apiUrl);
    this.setTranslateConfig();
    this.setAuthenticationConfig();
  }

  private setTranslateConfig(): void {
    this.translate.setDefaultLang(LocaleService.getDefaultLanguageISO());
    this.translate.use(LocaleService.getCurrentLanguageISO());
  }

  private setAuthenticationConfig(): void {
    if (whitelabel.press || whitelabel.professional) {
      this.authService.setRedirectState('authentication.login');
    } else {
      // this.authService.setRedirectState('home');
      this.authService.setRedirectState('catalog');
    }

    // this.authService.setUnauthorizedState('home');
    // this.authService.config.setRootState('home');
    this.authService.setUnauthorizedState('authentication.login');
    this.authService.config.setRootState('catalog');
    this.authService.config.setLoginUrl(`${this.appConfig.apiUrl}/authentication/login`);
    this.authService.config.setLogoutUrl(`${this.appConfig.apiUrl}/authentication/logout`);
    this.authService.config.setRefreshTokenUrl(`${this.appConfig.apiUrl}/authentication/refresh-token`);
    this.authService.config.setForgotPasswordUrl(`${this.appConfig.apiUrl}/authentication/password/forgot`);
    this.authService.config.setPasswordRestoreUrl(`${this.appConfig.apiUrl}/authentication/password/restore`);
  }
}

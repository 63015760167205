import { Component, ElementRef, Inject } from '@angular/core';
import { UserService } from './user.service';
import { User } from './user.model';
import { TranslateService } from '@ngx-translate/core';
import { UIRouter } from '@uirouter/angular';
import { ToastrService } from 'ngx-toastr';
import { whitelabel } from '../../../whitelabels/whitelabel';

interface Country {
  code: string;
  name: string;
}

interface Salutation {
  code: string;
  name: string;
}

@Component({
  selector: 'app-create-account',
  templateUrl: './user-create.component.html'
})

export class UserCreateComponent {
  public whitelabel = whitelabel;
  public user: User = new User();
  public countryList: Country[] = [];
  public salutations: Salutation[] = [];
  public invalidFormSubmit = false;

  constructor(
    @Inject('Window') private window,
    private userService: UserService,
    private translate: TranslateService,
    private router: UIRouter,
    private elRef: ElementRef,
    private toastr: ToastrService,
  ) {
    this.translate.get('country').subscribe((countries) => {
      Object.entries(countries).map((country) => {
        this.countryList.push({code: country[0].toString(), name: country[1].toString()});
      });
    });

    this.translate.get('salutations').subscribe((salutations) => {
      Object.entries(salutations).map((salutation) => {
        this.salutations.push({code: salutation[0].toString(), name: salutation[1].toString()});
      });
    });
  }

  public doCreateUser(form: any): void {
    if (form.valid) {
      this.invalidFormSubmit = false;
      this.userService.registerAccount(this.user).subscribe(() => {
        this.toastr.success(this.translate.instant('user.form.accountCreated'));
        this.router.stateService.go('authentication.login', this.router.stateService.params, {reload: true});
      });
    } else {
      this.toastr.error(this.translate.instant('user.form.invalid-submit'));
      const _firstInvalidInput = this.elRef.nativeElement.querySelector('.form-control.ng-invalid');

      // +115px to compensate for the sticky header
      if (_firstInvalidInput) {
        this.window.scrollTo(0, _firstInvalidInput.scrollTop + 115);
        _firstInvalidInput.focus();
      }
      this.invalidFormSubmit = true;
    }
  }
}

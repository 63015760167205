import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {DownloadService} from './download.service';
import {DownloadListComponent} from './download-list/download-list.component';
import {SlideshowModule} from '../slideshow/slideshow.module';

@NgModule({
  declarations: [
    DownloadListComponent,
  ],
  exports: [
  ],
  imports: [
    SlideshowModule,
    SharedModule,
  ],
  providers: [
    DownloadService,
  ]
})

export class DownloadModule {}

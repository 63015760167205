import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';
import { CrudModule } from '@ngx-ivengi/crud';
import { PaginationComponent } from './pagination/pagination.component';
import {FileSizePipe} from './file-size.pipe';

@NgModule({
  declarations: [
    PaginationComponent,
    FileSizePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule,
    CrudModule,
    TranslateModule,
    ModalModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    UIRouterModule,
    CrudModule,
    TranslateModule,
    ModalModule,
    PaginationComponent,
    FileSizePipe,
  ]
})

export class SharedModule {
}

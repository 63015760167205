import { Component, OnInit, ViewChild } from '@angular/core';
import { StaticService } from '../core/static.service';
import { homepageLinksModel } from './homepagelinks.model';
// import { SlideshowComponent } from '../slideshow/slideshow.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {
  public homepageLinks: homepageLinksModel = new homepageLinksModel();
  public homepageLoaded: boolean = false;

  // @ViewChild(SlideshowComponent)
  // protected slideshowComponent: SlideshowComponent;

  constructor(
    private staticService: StaticService
  ){}

  public ngOnInit(): void {
    this.initHomepage();
  }

  private initHomepage(): void {
    this.staticService.getHomepageLinks().subscribe((res) => {
      this.homepageLinks = res.links;
    }, (err) => {
      console.error(err);
    }, () => {
      this.homepageLoaded = true;
    });
  }
}

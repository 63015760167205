import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LxRequestFormComponent } from './lx-request-form.component';

export let CHILD_STATES: Ng2StateDeclaration[] = [
  {
    name: 'lx-request-form',
    url: '/request-form',
    component: LxRequestFormComponent,
    data: {
      insecure: false
    }
  }
];

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: CHILD_STATES
    }),
    TranslateModule
  ],
  exports: [UIRouterModule]
})

export class LxRequestFormRoutes {}

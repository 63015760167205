import {EventEmitter, Injectable} from '@angular/core';
import {CrudConfig} from '@ngx-ivengi/crud';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';

@Injectable()

export class CatalogService {
  public onBasketChange = new EventEmitter<void>();
  private httpParams: HttpParams = new HttpParams();

  constructor(
    private http: HttpClient,
    private config: CrudConfig,
    private cookieService: CookieService,
  ) {}

  public setHttpParams(httpParams: HttpParams): void {
    this.httpParams = httpParams;
  }

  public getHttpParams(): HttpParams {
    return this.httpParams;
  }

  public getBasketItems(): string[] {
    return this.cookieService.check('basket') ? this.cookieService.get('basket').split(',') : [];
  }

  public addBasketItem(itemId: string): void {
    this.cookieService.set('basket', Array.from(new Set([...this.getBasketItems(), itemId])).join(','));
    this.onBasketChange.emit();
  }

  public removeBasketItem(itemId: string): void {
    let items = this.getBasketItems();
    items = items.filter(_itemId => _itemId !== itemId);
    if (items.length === 0) {
      this.cookieService.delete('basket');
    } else {
      this.cookieService.set('basket', items.join(','));
    }
    this.onBasketChange.emit();
  }

  public search(httpParams: HttpParams = null): Observable<any> {
    return new Observable((observer) => {
      this.http.get(`${this.config.getApiUrl()}/catalog/search`, {params: httpParams}).subscribe(
        response => {
          observer.next(response);
        },
        err => {
          observer.error(err);
        }
        );
    });
  }

  public autocomplete(token): Observable<any> {
    return new Observable((observer) => {
      this.http.get(`${this.config.getApiUrl()}/catalog/autocomplete`, {params: { token: token } }).subscribe(
        response => {
          observer.next(response);
        },
        err => {
          observer.error(err);
        }
      );
    });
  }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LxRequestFormComponent } from './lx-request-form.component';
import { LxRequestFormService } from './lx-request-form.service';

@NgModule({
  declarations: [
    LxRequestFormComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    LxRequestFormService
  ]
})

export class LxRequestFormModule {}

import {Injectable} from '@angular/core';
import {CrudService } from '@ngx-ivengi/crud';
import {DownloadMultiple} from './download-multiple.model';
import {DownloadSingle} from './download-single.model';
import {DownloadType} from './download-base.model';

@Injectable()
export class DownloadService extends CrudService<DownloadSingle|DownloadMultiple> {
  public entityType: DownloadSingle|DownloadMultiple;
  public entityName = 'download';

  public entityFactory(data: any): DownloadMultiple|DownloadSingle {
    if (data.type === DownloadType.SINGLE) {
      const download = new DownloadSingle();
      download.fromResponse(data);
      return download;
    }

    if (data.type === DownloadType.MULTIPLE) {
      const download = new DownloadSingle();
      download.fromResponse(data);
      return download;
    }
  }
}
